<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>通知の登録</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="true">
                      <template v-slot:label>本文</template>
                      <template v-slot:labelNote>（300文字以内）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <textarea
                            v-trim
                            v-maxlength
                            maxlength="300"
                            v-model="form.message"
                            :class="{ 'form-control form-textarea': true, 'is-error': errors.message }"
                            name="message"
                          ></textarea>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="$permission.isOffice()">
                      <template v-slot:label>対象イベント</template>
                      <template v-if="$permission.isGmo()" v-slot:labelNote>（未選択はマスターのみ可能、選択しない場合は全店舗が対象になります）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-select">
                            <select
                              :class="{ 'form-control form-select-input': true, 'is-error': errors.target.event }"
                              name="event"
                              v-model="form.target.event"
                            >
                              <option value="" selected>選択してください</option>
                              <option
                                v-for="item in parentEventList"
                                :key="item.index"
                                :value="item.subdomain"
                              >{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow v-if="hasShopRole">
                      <template v-slot:label>対象店舗</template>
                      <template v-slot:labelNote>（選択しない場合は全店舗が対象になります）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <SearchSelect
                            ref="searchSelect"
                            singleLabel="name"
                            :disabled="!form.target.event"
                            :isError="errors.target.shop"
                            :options="shopList"
                            :multiple="false"
                            :closeOnSelect="true"
                            :searchKeyMinLength="searchKeyLength"
                            :searchAction="searchAction"
                            :resetOptions="resetOptions"
                            :params="params"
                            :searchField="searchField"
                            @change-selection="handleSelectedShop"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>期限</template>
                      <template v-slot:labelNote>（指定しない場合は無期限になります）</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <DateRange
                            field="publicDate"
                            startField="startDate"
                            endField="endDate"
                            :value="form.publicDate"
                            :errorField="errors.publicDate"
                            @on-change="onChangeDateRange"
                          />
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link
              class="btn btn-white"
              :to="{ name: 'Notification' }"
              >キャンセル</router-link
            >
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="register" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixin
import error from '@/mixins/plugin/error';
import nav from '@/mixins/nav/notification';
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';
//component
import FormRow from '@/components/FormRow.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import DateRange from '@/components/DateRange.vue';

export default {
  name: 'Home',
  data: function() {
    return {
      pageName: '通知',
      form: {
        message: '',
        target: {
          event: '',
          shop: '',
        },
        publicDate: {
          startDate: '',
          endDate: '',
        },
      },
      params: {
        status: StatusConstants.shop.approved.value,
      },
      searchAction: 'shop/getShopList',
      resetOptions: 'shop/RESET_SHOP_LIST',
      searchField: 'name',
      prefixs: ['publicDate', 'target'],
    };
  },
  created () {
    if (!this.isOfficeHadEvent) {
      this.$router.push({ name: '404' });
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    await this.$store.dispatch('event/getParentEventList', { limit: 9999 });
    this.$loading.clear(loading);
  },
  computed: {
    ...mapGetters({
      parentEventList: 'event/parentEventList',
      shopList: 'shop/shopList',
      isOfficeHadEvent: 'auth/isOfficeHadEvent',
      hasShopRole: 'auth/hasShopRole'
    }),
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
  },
  watch: {
    'form.target.event'(val) {
      this.$refs.searchSelect.resetSelection();
      this.form.target.shop = '';
      this.$store.commit(this.resetOptions);
      if (val) {
        this.params = {
          ...this.params,
          subdomain: val,
        }
      }
    },
  },
  validations() {
    const form = {
      message: { required, maxLength: maxLength(300) },
    };
    if(this.form.publicDate?.startDate || this.form.publicDate?.endDate) {
      form.publicDate = {
        startDate: { selectRequired: required },
        endDate: { selectRequired: required },
      }
    }
    if(this.$permission.isOffice()) {
      form.target = {
        event: { selectRequired: required }
      }
    }
    return { form }
  },
  components: {
    FormRow,
    SearchSelect,
    DateRange,
  },
  mixins: [nav, error],
  methods: {
    handleSelectedShop(item) {
      this.form.target.shop = item?.id;
    },
    async register() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'notification');
      } else {
        const result = await this.$store.dispatch('notification/createNotification', this.form);
        if (result) {
          await this.$router.push({ name: 'Notification' });
          this.$message.created('newNotification');
        }
      }
    },
    onChangeDateRange(field, type, val) {
      this.form[field] = {
        ...this.form[field],
        [type]: val,
      }
    },
  },
};
</script>
