export default {
  data: function() {
    return {
      pageNavs: [
        {
          id: '01',
          name: '一覧',
          path: '/notification',
          routeName: 'Notification',
          includes: false,
        },
        {
          id: '02',
          name: '新規登録',
          path: '/notification/register',
          routeName: 'NotificationRegister',
          includes: false,
          eventFlag: true,
        },
      ],
    };
  },
};
